<template>
  <v-row class="mx-auto table-tickets">
    <v-col cols="12" class="pl-0 pt-0 mt-0">
      <v-btn class="ml-0"
        elevation="0"
        :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]"
        v-show="!manyGroup" @click="goBackX"
        :color="$store.state.corpInfo.darkTheme?'black':'rgb(226, 230, 239)'">
        <v-icon :color="$store.state.corpInfo.darkTheme?'white':'black'" class="mr-2 text-uppercase"> mdi-arrow-left </v-icon>
        {{ $t("actions.goBack") }}
      </v-btn>
    </v-col>
    <v-col lg="4" md="5" cols="12" class="table-tickets-containers">
      <v-row class="px-6 pt-5 pb-2 table-tickets-image">
        <v-col cols="12">
          <Gallery ref="GalleryDialog" />
          <div class="ImgTickets mx-auto">
            <div class="back" :class="[{'light':!$store.state.corpInfo.darkTheme}]"></div>
            <v-carousel
              class="front" 
              :class="[{'light':!$store.state.corpInfo.darkTheme}]"
              interval="3500"
              cycle
              height="191px"
              hide-delimiter-background
              show-arrows-on-hover>
              <v-carousel-item
                @click="show(tickets)" 
                v-for="(slide, i) in tickets.images"
                :key="i"
                :src="$root.UrlGallery + slide" >
              </v-carousel-item>
            </v-carousel>
          </div>
        </v-col>
        <v-col cols="12">
          <p class="title-info">
            {{tickets.title[$root.getTextlang()]}}
          </p>
          <p class="descrip-info mb-3 text-justify">
            {{tickets.description[$root.getTextlang()]}}
          </p>
        </v-col>
        <v-col cols="12" align-self="center">
          <div v-for="(info, key) in tickets.info[$root.getTextlang()]" :key="key" class="info-container mb-2">
            <v-icon :color="$store.state.corpInfo.darkTheme?'white':'black'" size="15" style="min-width: 15px;"> {{ info.icon }} </v-icon>
            <span class="descrip-info">
              {{ info.info }}
            </span>
          </div>
        </v-col>
        <v-col cols="12">
          <v-btn class="cart-button" @click="addCart">
            <span class="add-to-cart white--text text-wrap">{{ $t('actions.addToCart') }}</span>
            <span class="added white--text">{{ $t('actions.ready') }}</span>
            <v-icon color="white" class="fa-shopping-cart">mdi-cart</v-icon>
            <v-icon class="fa-box">mdi-ticket</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col lg="8" md="7" cols="12" class="table-tickets-containers">

      <div v-if="!tickets || !tickets.ticketsG || tickets.ticketsG.length === 0" class="mx-auto ml-3 grey--text text--lighten-1">{{ $t("messages.noTickets") }}</div>
      
      <table v-if="tickets && tickets.ticketsG && tickets.ticketsG.length > 0" class="simple-table-tickets-item">
        <tbody>
          <tr v-for="(item, index) in tickets.ticketsG" :key="`ticketItem-${item.id_boleto}`" class="white--text">
            <td class="py-2 item-description">
              <strong :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'grey--text text--darken-4':!$store.state.corpInfo.darkTheme}]">
                {{item.alias2[$root.getTextlang()]}}
              </strong> <br />
              <v-icon x-small class="mr-1" v-if="item.requirements" color="var(--color-primary)">mdi-alert</v-icon>
              <span style="color: var(--color-primary);">{{item.requirements ? item.requirements[$root.getTextlang()] : ''}}</span>
            </td>
            <td>
              <div v-if="refreshing" class="d-flex justify-center align-center input-amount-tickets">
                <v-btn icon :dark="$store.state.corpInfo.darkTheme" @click="ticketQuantity(item, index, -1)">
                  <v-icon large> mdi-minus </v-icon>
                </v-btn>
                <input
                  v-model="item.cantidad"
                  :style="`background-color: ${
                    item.cantidad > 0 ? 'green' : 'grey'
                  }`"
                  class="mx-3 px-6 rounded-lg text-h6 input-quantity"
                  maxlength="3"
                  @input="checkit(item, index)"
                  type="text"
                  v-mask="'###'"
                />
                <v-btn icon :dark="$store.state.corpInfo.darkTheme" @click="ticketQuantity(item, index, 1)">
                  <v-icon large> mdi-plus </v-icon>
                </v-btn>
              </div>
            </td>
            <td align="center">
              <p class="price-ticket text-h5 mb-0"
                :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
                $ {{item.price}}
                <sup>MXN</sup>
              </p>

              <v-expand-transition>
                <p
                  class="text-body-1 mb-0"
                  :class="[{'grey--text text--lighten-1':$store.state.corpInfo.darkTheme}, {'grey--text text--darken-3':!$store.state.corpInfo.darkTheme}]"
                  v-show="item.cantidad > 0"
                >
                  <sub>{{ $t("actions.cardinfo.subtotal") }}</sub> $ {{ item.price * item.cantidad }}.00
                  <sup>mx</sup>
                </p>
              </v-expand-transition>
            </td>
          </tr>
        </tbody>
      </table>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import Gallery from '../Tools/GalleryDialog.vue';

export default {
  name: "SubgroupInputTable",
  props: ["tickets", "tab", "manyGroup"],
  components:{
    Gallery
  },
  data() {
    return {
      cantidadTem: 0,
      sumaTem : 0,
      refreshing: false,
      vEntorno : null
    }
  },
  mounted() {
    this.vEntorno = this.$store.state.sites;
    this.refreshing = true;
  },
  methods: {
    ...mapActions({      
      upgradeCartTickets: 'shopping/updateCrossPurchase'
    }),
    ticketQuantity (item, index, value) {
      this.refreshing = false;
      let sumTemp = parseInt(item.cantidad) + parseInt(value);
      if(sumTemp >= 0 && sumTemp <= 20) {
        this.tickets.ticketsG[index].cantidad = parseInt(item.cantidad) + parseInt(value)
        this.tickets.ticketsG[index].total = parseInt(item.cantidad) * parseFloat(item.price);
      }
      this.refreshing = true;
    },
    addCart () {
      // if($('.cart-button').hasClass('clicked')) {
      //   return false;
      // }
      
      let ticketsGTem = this.tickets.ticketsG;

      let addCart = ticketsGTem.filter(({ cantidad }) => cantidad > 0);

      if(!addCart || addCart.length === 0) {
        this.$root.Toast("error", "", this.$t("messages.ticketsNumberMustZero"));
        return false
      }
      // this.upgradeCartTickets(addCart);
      this.checkCart(addCart);    
    },
    checkCart (addTickets) {
      let cantidadT = this.$store.getters['shopping/getNumberCrossed']
      if(cantidadT <= 20) {
        // $(".cart-button").addClass("clicked")
        // setTimeout(() => {
        //   $(".cart-button").removeClass("clicked")
        // }, 2700);
        this.updateCart(addTickets);
        // this.$root.Toast("success", "", this.$t("messages.ticketsUpdated"));
      } else {
        this.$root.Toast("warning", "", this.$t("messages.ticketsNumberMustLessHundred"));
      }    
    },
    updateCart (addTickets) {
      let updatingTickets = this.$store.state.shopping.crossSellTickets;

      addTickets.forEach(ticket => {
        let index = updatingTickets.findIndex( ({ id_boleto }) => id_boleto === ticket.id_boleto)
        if(index === -1) {
          updatingTickets = [ ...updatingTickets , ticket]
        } else {
          updatingTickets[index].cantidad = parseInt(updatingTickets[index].cantidad) + parseInt(ticket.cantidad)
          updatingTickets[index].total = parseInt(updatingTickets[index].cantidad) * Number(updatingTickets[index].price)
        }
      });
      this.upgradeCartTickets(updatingTickets);
      if (this.$route.name != "shoppingCart") {
        this.$router.push({ name: "shoppingCart" });
      }
    },
    checkit(val, index) {      
      this.refreshing = false;
      
      if(parseInt(val.cantidad) > 20) {
        this.tickets.ticketsG[index].cantidad = 20;
      }

      val.cantidad = parseInt(val.cantidad)
      if(!Number.isInteger(val.cantidad)) {
        this.tickets.ticketsG[index].cantidad = 0;
      }

      this.refreshing = true;
    },
    show(item) {
      this.$refs.GalleryDialog.begin(item);
    },
    goBackX() {
      this.refreshing = false;
      this.$emit('hideTickets');
    }
  }
};
</script>

<style scoped>
.table-tickets {
  max-width: 1300px;
}

.table-tickets-image {
  border: 1px solid #ccc;
}

.simple-table-tickets {
  background-color: transparent
}

.button-addCart-text {
  display: block;
}

.button-addCart-text-small {
  display: none;
}

.ImgTickets {
 width: 286px;
 height: 191px;
 position: relative;
}

.ImgTickets .back {
 width: 100%;
 height: 100%;
 top: calc(0% - 12px);
 left: calc(0% - 12px);
 background: #1e1d1d;
 border-radius: 10px;
 position: absolute;
 z-index: 1;
}

.ImgTickets .front {
  border: 1px solid rgb(43, 43, 43);
  width: 100%;
  height: 100%;
  top: 0px;
  border-radius: 10px;
  z-index: 2;
  position: absolute;
  background-size: cover;
  cursor: pointer;
}
.light {  
  border: 1px solid #fcfcfc !important;
  background: #dbdbdb !important;
}
.title-info {
 font-size: 20px;
}

.descrip-info {
 font-size: 12px;
}

.input-quantity {
  text-align: center;
  color: white;
  max-width: 100px;
}

.info-container {
 display: flex;
 flex-direction: row;
 gap: 10px;
 justify-content: flex-start;
 align-items: center;
}

.price-ticket {
  white-space: nowrap;
}

table.simple-table-tickets-item {
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

table.simple-table-tickets-item tr {
  padding: .35em;
}

table.simple-table-tickets-item td {
  padding: .625em;
  text-align: center;
  border: 0px solid;
  border-bottom: 1px solid #ddd;
}

table.simple-table-tickets-item tr td:first-child {
  text-align: left;
}

table.simple-table-tickets-item tr:last-child td {
  border-bottom-width: 0px;
}
.cart-button {
	position: relative;
	padding: 10px;
	width: 100%;
	height: 60px !important;
	border: 0;
	border-radius: 10px;
	background-color: #4a8d03 !important;
	outline: none;
	transition: .3s ease-in-out;
	overflow: hidden;
}
.cart-button:hover {
	background-color: #09a316 !important;
}
.cart-button:active {
	transform: scale(.9);
}

.cart-button .fa-shopping-cart {
	position: absolute;
	z-index: 3;
	top: 50%;
	left: -20%;
	font-size: 40px;
	transform: translate(-50%,-50%);
  animation-delay: .3s;
}
.cart-button .fa-box {
	position: absolute !important;
	z-index: 2;
	top: calc(0% - 50px);
	left: 50%;
  color: orange;
	font-size: 1.2em;
	transform: translate(-50%,-50%);
}
.cart-button span {
	position: absolute;
	z-index: 3;
	left: 50%;
	top: 50%;
	font-size: 1.2em;
	color: rgb(8, 5, 5);
	transform: translate(-50%,-50%);
}
.cart-button span.add-to-cart {
  font-size: 15px;
	opacity: 1;
  min-width: 250px !important;
  line-height: 22px;
}
.cart-button span.added {
	opacity: 0;
}

.cart-button.clicked .fa-shopping-cart {
	animation: cart 1.5s ease-in-out forwards;
}
.cart-button.clicked .fa-box {
	animation: box 1.5s ease-in-out forwards;
}
.cart-button.clicked span.add-to-cart {
	animation: txt1 1.5s ease-in-out forwards;
}
.cart-button.clicked span.added {
	animation: txt2 1.5s ease-in-out forwards;
}
@keyframes cart {
	0% {
		left: -10%;
	}
	40%, 60% {
		left: 50%;
	}
	100% {
		left: 125%;
	}
}
@keyframes box {
	0%, 40% {
		top: calc(0% - 50px);
	}
	60% {
		top: calc(0% - 7px);
		left: 50%;
	}
	100% {
		top: calc(0% - 7px);
		left: 125%;
	}
}
@keyframes txt1 {
	0% {
		opacity: 1;
	}
	20%, 100% {
		opacity: 0;
	}
}
@keyframes txt2 {
	0%, 80% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media screen and (max-width: 600px) {  
  table.simple-table-tickets-item tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table.simple-table-tickets-item td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table.simple-table-tickets-item tr td:first-child {
    text-align: left;
  }

  table.simple-table-tickets-item tr td {
    border-bottom-width: 0px;
  }

  table.simple-table-tickets-item tr:last-child {
    border-bottom-width: 0px;
  }
}

@media (max-width: 1300px) {
  .table-tickets {
    margin-left: 30px !important;
    margin-right: 30px !important;
    width: auto;
  }
}

@media (max-width: 959px) {
  .table-tickets-image {
    max-width: 500px;
    margin: 0px auto;
  }
}


@media (max-width: 700px) {
  .table-tickets {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

@media (max-width: 500px) {
  .ImgTickets {
    width: 100%;
    padding-left: 12px;
    box-sizing: border-box;
  }

  .ImgTickets .front {
    background-position: 50% 50%;
    width: calc(100% - 12px);
  }

  .ImgTickets .back {
    left: 0;
    width: calc(100% - 12px);
  }

  .button-addCart {
    width: 100%;
  }

  .button-addCart > span.v-btn__content {
    display: none;
  }

  .table-tickets-image {
    padding: 15px 5px 10px !important;
  }

  .table-tickets-containers {
    padding-left: 0px;
  }

  .button-addCart-text {
    display: none;
  }

  .button-addCart-text-small {
    display: block;
  }
}
</style>