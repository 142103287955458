var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item)?_c('v-card',{class:[{'white--text':_vm.$store.state.corpInfo.darkTheme}, {'black--text':!_vm.$store.state.corpInfo.darkTheme}],attrs:{"flat":"","height":"100vh","color":_vm.$store.state.corpInfo.darkTheme?'black':'#E2E6EF'}},[_c('div',{staticClass:"imgeSite",staticStyle:{"cursor":"pointer"},style:(_vm.item.img?_vm.styleWithImage:_vm.styleNoImage)},[_c('div',{staticClass:"imgShadow",style:({        
        background: `linear-gradient(
          to bottom,
          ${_vm.$store.state.corpInfo.darkTheme ? 
          '#00000000 0%, #000000 100%':'#ffffff00 0%, #E2E6EF 100%'}
        )`})})]),_c('div',{staticClass:"contInfo"},[_c('div',{staticClass:"backBtn"},[_c('v-btn',{attrs:{"icon":"","x-large":"","dark":_vm.$store.state.corpInfo.darkTheme},on:{"click":function($event){return _vm.$root.goHome()}}},[_c('v-icon',{staticClass:"backBtnIcon",attrs:{"large":""}},[_vm._v(" mdi-home ")])],1)],1),_c('div',[_c('h1',[_vm._v(_vm._s(_vm.item.name[_vm.$root.getTextlang()]))]),_c('h4',[_vm._v(_vm._s(_vm.$t("actions.categorias")[_vm.item.id_eventcata].title))])]),(_vm.item)?_c('div',[_c('div',{staticClass:"DivAbout",class:[{'open':_vm.aboutDroppedDown}]},[(_vm.item && _vm.item.acercade)?_c('div',{attrs:{"align":"left"},domProps:{"innerHTML":_vm._s(_vm.item.acercade[_vm.$root.getTextlang()])}}):_vm._e()]),(_vm.item && _vm.item.acercade && _vm.item.acercade[_vm.$root.getTextlang()] && _vm.item.acercade[_vm.$root.getTextlang()].length > 0)?_c('div',{attrs:{"align":"center"}},[_c('v-btn',{attrs:{"icon":"","x-large":"","dark":_vm.$store.state.corpInfo.darkTheme},on:{"click":function($event){return _vm.toggleAbout()}}},[(_vm.aboutDroppedDown)?_c('v-icon',{staticClass:"btnArrow"},[_vm._v(" mdi-arrow-up ")]):_vm._e(),(!_vm.aboutDroppedDown)?_c('v-icon',{staticClass:"btnArrow"},[_vm._v(" mdi-arrow-down ")]):_vm._e()],1)],1):_vm._e()]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEventContent),expression:"showEventContent"}],staticClass:"mt-n8"},[((_vm.vEntorno && _vm.vEntorno.events[0].event_config === 4 && _vm.vEntorno.events[0].categories && _vm.vEntorno.events[0].categories.length !== 0) 
      || (_vm.vEntorno.events[0].event_config === 5))?_c('div',[(!_vm.$store.state.sites.events[0].avialableDates)?_c('v-container',{staticClass:"table-tickets-image"},[_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-skeleton-loader',{attrs:{"dark":"","min-height":"400px","type":"date-picker"}})],1),_c('v-col',{attrs:{"md":"8","cols":"12"}},_vm._l((3),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"mb-2",attrs:{"max-height":"160px","dark":"","type":"article"}})}),1)],1)],1):_vm._e(),(_vm.$store.state.sites.events[0].avialableDates && _vm.loadingTicketsCategory && _vm.showTickets)?_c('v-container',{staticClass:"table-tickets-image"},[_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-skeleton-loader',{attrs:{"dark":_vm.$store.state.corpInfo.darkTheme,"min-height":"400px","type":"date-picker"}})],1),_c('v-col',{attrs:{"md":"8","cols":"12"}},_vm._l((3),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"mb-2",attrs:{"dark":_vm.$store.state.corpInfo.darkTheme,"max-height":"160px","type":"article"}})}),1)],1)],1):_vm._e(),(_vm.$store.state.sites.events[0].avialableDates)?_c('div',[(_vm.$store.state.sites.events[0].avialableDates)?_c('DateList',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showTickets),expression:"!showTickets"}],attrs:{"resetDates":_vm.vEntorno.dialogDates},on:{"displayTickets":_vm.displayTickets,"loadCategories":_vm.loadingCategories}}):_vm._e(),(_vm.tickets && !_vm.loadingData)?_c('TableTicketsComplex',{attrs:{"loadingTicketsCategory":_vm.loadingTicketsCategory,"tickets":_vm.tickets,"event":_vm.event},on:{"hideTickets":_vm.hideTickets}}):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.vEntorno.events[0].event_config === 4 && _vm.vEntorno.events[0].categories.length === 0)?_c('div',{staticClass:"mx-auto my-5 text-center grey--text"},[_vm._v(" "+_vm._s(_vm.$t('messages.noTickets'))+" ")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }