<template>
 <v-dialog
    v-model="openGallery"
    width="600">
   <v-card :color="$store.state.corpInfo.darkTheme?'black':'white'">
    <v-carousel v-if="openGallery" width="100%" height="100%" :show-arrows="true">
     <v-carousel-item
       class="img-style"
       v-for="(item, i) in images"
       :key="i"
       eager>
      <v-img :src="$root.UrlGallery + item" height="100%" width="100%" eager lazy-src= contain >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-carousel-item>
    </v-carousel>
    <v-card-actions>
      <v-btn block class="mx-0"
          :dark="$store.state.corpInfo.darkTheme"
          :color="$store.state.corpInfo.darkTheme?'black':'white'"
          @click="openGallery=false">
          <span>{{ $t("actions.toClose") }}</span>
       </v-btn>
    </v-card-actions>
   </v-card>
 </v-dialog> 
</template>

<script>
export default {
 name: 'Gallery',
 props: ['groupX'],
  data() {
    return {
      openGallery: false,
      images: [],
    }
  },
  methods: {
    begin (item) {
      this.images = item.images;
      if(this.images[0] !== null) this.openGallery = true;
    }
  }
}
</script>

<style scoped>
.img-style {
  min-width:600px !important; 
  min-height: 400px !important;
}
.btn {
  position: absolute;
  z-index: 999;
  top: calc(0% - 0px);
  right: calc(0% + 50px);
  height: 40px;
  width: 40px;
}
@media (max-width: 700px) {
  .img-style {
    min-width:100% !important; 
    min-height: 100% !important;
  }
}
</style>