<template>
  <v-card flat width="100%" :dark="$store.state.corpInfo.darkTheme" color="transparent">
    <v-card-title class="mb-3 title-no-events d-flex justify-center text-uppercase pb-10">{{ $t("messages.noEvents") }}</v-card-title>
    <v-card-text>
      <v-container class="next-date" v-if="nextDate">
        <v-icon size="40px">mdi-calendar</v-icon>
        <span class="text-center close-date" :class="[{'light': !$store.state.corpInfo.darkTheme}]"> {{ $t("messages.nextCloseDate") }}: </span>
        <span class="text-center date-style" :class="[{'light': !$store.state.corpInfo.darkTheme}]" 
          @click="$emit('changeDate', nextDate)"> {{ getDateAvailability(nextDate) }} </span>
      </v-container>      
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'RecommendationDate',
  props: ["nextDate"],
  methods: {
    getDateAvailability(date) {
      let dateX = new Date(date)
      dateX.setMinutes(dateX.getMinutes() + dateX.getTimezoneOffset())
      return dateX.toLocaleDateString(this.$root.getTextlang(), { year:"numeric", month:"long", day:"numeric"}) 
    }
  }
}
</script>

<style scoped>
.title-no-events {
  border-bottom: 1px solid #6d6d6d;
  color: #a4a4a4;
  text-align: center;
}
.next-date {
  margin: 25px auto; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 350px;
}
.date-style {
  margin-top: 10px;
  line-height: 35px;
  color: white;
  cursor: pointer;
  font-size: 25px;
}
.date-style:hover {
  margin-top: 10px;
  line-height: 35px;
  color: var(--color-primary);
  font-size: 25px;
  cursor: pointer;
}
.close-date {
  color: white;
  font-size: 20px;
}.light {
  color: black;
}
</style>