<template>
  <div>
    <v-row class="d-flex flex-row align-center" style="width: 100% !important;">
      <v-col cols="12" md="2" lg="3"
        class="d-flex align-center flex-column show">
        <div align="center">
          <div class="Font1" :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
              {{$t("calendary.Month["+(parseInt(item.mes) -1)+"]").substr(0, 3)}}
          </div>
          <div class="Font2" :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]"> 
            {{ item.fecev }}
          </div>
          <div class="Font1" :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]"> 
            {{ item.yearev }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" lg="5">
        <v-list-item-content class="pb-0">
          <v-list-item-subtitle class="overline text-center text-md-left text-lg-left text-schedule"
            :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
              {{$t("calendary.Day["+parseInt(item.dianum)+"]").substr(0, 3)}} - {{ item.time_event }} HRS
          </v-list-item-subtitle>
          <v-list-item-title class="subtitle-2 font-weight-bold text-center text-md-left text-lg-left"
            :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
            <div v-if="vEntorno">
              {{ vEntorno.events[vEntorno.indexEveSel].city }},
              {{ vEntorno.events[vEntorno.indexEveSel].state }}.
              {{ vEntorno.events[vEntorno.indexEveSel].place }}
            </div>
          </v-list-item-title>
          <v-list-item-title class="subtitle-1 text-center text-md-left text-lg-left"
            :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
            {{ vEntorno.events[vEntorno.indexEveSel].name[$root.getTextlang()] }}
          </v-list-item-title>
          <v-list-item-title class="subtitle-1 text-center text-md-left text-lg-left"
            :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
            {{ item.description }}
          </v-list-item-title>

          <div v-if="vEntorno.events[vEntorno.indexEveSel].event_config === 4" class="available-container">                   
            <div class="disp aviso">
              <div>
                <v-btn v-if="!item.cancel" dark :style="[item.quedan > 0 && !item.cancel ? {'background-color':'green !important'}:{'background-color':'grey !important'}]">
                  {{ item.quedan }}
                </v-btn>
              </div>
              <span class="ml-2" 
                :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]" 
                v-if="item.quedan > 0 && !item.cancel">
                {{ $t("actions.limitedAvai") }} 
              </span>
              <span class="ml-2" v-if="item.quedan == 0 && !item.cancel"
                :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
                {{ $t("actions.limitedAvai2") }} 
              </span>
              <span v-if="item.cancel" class="ml-2"
                :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
                {{ $t("actions.canceled") }}
              </span>
            </div>
          </div>

          <div v-else class="available-container2">
            <div class="aviso" :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
              <v-icon size="10px" :color="item.cancel || item.quedan <= 0 ? 'red' : 'green'">mdi-circle</v-icon>
              <div v-if="!item.cancel">
                <span class="ml-2" v-if="item.quedan > 0">{{ $t("actions.limitedAvai") }} </span>
                <span class="ml-2" v-else>{{ $t("actions.limitedAvai2") }} </span>
              </div>
              <div v-else>
                <span class="ml-2">{{ $t("actions.canceled") }}</span>
              </div>
            </div>
          </div>

        </v-list-item-content>
      </v-col>
      <v-col cols="12" md="4" lg="4" 
        class="d-flex align-center align-md-end flex-column BtnRespon">
        <v-btn dark class="caption btn-respnsive" :disabled="BntStatus(item)"
          :style="[parseInt(item.usados) < parseInt(item.max_seat) && !item.cancel? {'background-color':'var(--color-primary) !important'}:{'background-color':'grey !important'}]" 
          @click="OpenMap(item, index)">
          {{ $t("actions.findTicket") }}
        </v-btn>
      </v-col>
      <div class="divider" :class="[{'light':!$store.state.corpInfo.darkTheme}]" v-if="EventX2[page-1].length-1 !== index"></div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'dateComponent',
  props: ['item', 'index', 'EventX2', 'page'],
  data() {
    return {
      vEntorno: this.$store.state.sites
    }
  },
  methods: {
    OpenMap(item, index) {
      this.$emit('selectFindTickets', item, index)
    },
    BntStatus(item){
    let status = true
    if(!item.cancel){
      status = parseInt(item.usados) >= parseInt(item.max_seat)
    }
    return status
   }
  }
}
</script>

<style scoped>
.available-container {
  padding: 5px 0px; 
  display: flex; 
  justify-content: left;
}
.available-container2 {
  padding: 15px 0px; 
  display: flex; 
  justify-content: flex-start;
}
.text-schedule {
  font-size: 16px !important;
}
 .BtnRespon{
 align-items: center !important;
}
.divider{
  border-top: 5px solid #000000;
  width: 100%;
  padding-bottom: 15px;
}.light {
  border-top: 5px solid #DFE3EC;
}
.Font1 {
 font-size: 16px;
 font-weight:500;
}
.Font2 {
 font-size: 22px;
 font-weight: bold;
}
.disp {
 border: 1px solid rgb(191, 191, 191);
 padding: 0px 8px 0px 8px;
 border-radius: 4px;
 width: 80%;
}
.aviso {
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 8px 0px;
 font-weight: 600;
 font-size: 12px;
 text-transform: uppercase;
}
.btn-respnsive {
  min-height: 60px;
  width: 100%;
}
@media only screen and (max-width: 1200px) {
 .available-container {
    justify-content: center;
  }
}

@media only screen and (max-width: 800px) {
  .show {
    display: none !important;
  }
 .disp {
    width: 100%;
  }
  .btn-respnsive {
    min-height: 40px;
    margin-bottom: 10px;
  }
  .available-container {
    justify-content: center;
  }
  .available-container2 {    
    justify-content: center;
  }
}
</style>