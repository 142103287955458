<template>
  <v-card flat height="100vh" :color="$store.state.corpInfo.darkTheme?'black':'#E2E6EF'"
    :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]" 
    v-if="item">
    <div
      class="imgeSite"
      style="min-height: 300px;"
      :style="item.img?styleWithImage:styleNoImage"
    >
      <div class="imgShadow" 
        :style="{        
          background: `linear-gradient(
            to bottom,
            ${$store.state.corpInfo.darkTheme ? 
            '#00000000 0%, #000000 100%':'#ffffff00 0%, #E2E6EF 100%'}
          )`}"> </div>
    </div>
    <div class="contInfo">
      <div class="backBtn">
        <v-btn icon :dark="$store.state.corpInfo.darkTheme" x-large class="pointman menu-option" @click="openCart">
          <div class="MyBadge" v-if="$store.state.shopping.crossSellTickets.length > 0">
            {{ $store.state.shopping.crossSellTickets.length }}
          </div>
          <v-icon size="40px" class="icon-account-cart">mdi-cart-outline</v-icon>
        </v-btn>
        <v-btn icon x-large :dark="$store.state.corpInfo.darkTheme" @click="$root.goHome()">
          <v-icon large class="backBtnIcon">
            mdi-home
          </v-icon>
        </v-btn>
      </div>
      <div>
        <h1>{{ item.name[$root.getTextlang()] }}</h1>
        <h4>{{ $t("actions.categorias")[item.id_eventcata].title }}</h4>
      </div>
      <div v-if="item">
        <div class="DivAbout" :class="[{'open':aboutDroppedDown}]">
          <div v-html="item.acercade[$root.getTextlang()]" align="left"></div>
        </div>
        <div align="center" 
          v-if="item && item.acercade && item.acercade[$root.getTextlang()] && item.acercade[$root.getTextlang()].length > 0">
          <v-btn icon x-large :dark="$store.state.corpInfo.darkTheme" @click="toggleAbout()">
            <v-icon class="btnArrow" v-if="aboutDroppedDown">
              mdi-arrow-up
            </v-icon>
            <v-icon class="btnArrow" v-if="!aboutDroppedDown">
              mdi-arrow-down
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <div v-if="showEventContent">
      <div v-if="vEntorno.events[vEntorno.indexEveSel].categories.length !== 0">
        <div class="mb-5 mt-n3" v-if="vEntorno.events[vEntorno.indexEveSel].tabs">
          <div class="Mytab mx-auto" :class="[{'light':!$store.state.corpInfo.darkTheme}]">
            <div
              v-for="(item) in vEntorno.events[vEntorno.indexEveSel].tabs"
              class="tab"
              :style="{
                'cursor': loadingTicketsCategory||loadingData?'default':'pointer'
              }"
              @click="tab == item || changetab(item)"
              :class="tab == item ? 'active' : ''"              
            >
            {{ $t(`actions.categories[${item}]`) }}
            </div>
          </div>
        </div>
        <div class="pb-8 px-0">
          <v-container v-if="loadingData && showTickets" class="table-tickets-image">
            <v-row>
              <v-col md="4" cols="12">
                <v-skeleton-loader 
                  :dark="$store.state.corpInfo.darkTheme"
                  type="card-avatar, actions">
                </v-skeleton-loader>
              </v-col>
              <v-col md="8" cols="12">
                <v-skeleton-loader                   
                  :dark="$store.state.corpInfo.darkTheme"
                  type="table-row-divider@6">
                </v-skeleton-loader>
              </v-col> 
            </v-row>
          </v-container>
  
          <EventCategories
            class="mx-auto"
            v-if="!showTickets"
            :item ="currentTicketGroup" 
            :tab="tab" 
            @displayTickets="displayTickets" 
          />
                    
          <TableTickets
            class="mx-auto"
            v-if="!loadingData && tickets"  
            v-show="showTickets" 
            :tickets="tickets" 
            :tab="tabs" 
            :manyGroup="manyGroups"
            @hideTickets="tickets = null; showTickets = false"
          />
        </div>
      </div>
      <div v-if="vEntorno.events[vEntorno.indexEveSel].categories.length === 0" class="mx-auto my-5 text-center grey--text">
        {{ $t('messages.noTickets') }}
      </div>
    </div>
  </v-card>
</template>

<script>
import EventCategories from "../Tools/EventCategories.vue";
import TableTickets from "../Events/TableTickets.vue";

export default {
  name: "freeEntryEvent",
  props: ["item", "loadingData", "loadingTicketsCategory"],
  data() {
    return {
      tab: 0,
      tabs: [],
      vEntorno: null,
      aboutDroppedDown: false,
      showEventContent: true,
      tickets : null,
      showTickets : true,
      loadA: false,
      currentTicketGroup : null,
      manyGroups : false,
      styleWithImage: { 
        backgroundImage: 'url(' + this.$root.pathevens + this.item.img + ')', 
        height: this.aboutDroppedDown ? '100vh' : '300px' 
      },
      styleNoImage: {
        height: this.aboutDroppedDown ? '100vh' : '300px'
      }
    };
  },
  components: {
    EventCategories,
    TableTickets
  },
  watch: {
    tab: function() {
      this.checkGroups();
    }
  }, 
  beforeMount(){
    this.vEntorno = this.$store.state.sites;
  },
  mounted() {
    this.begin();
  },
  methods: {
    begin() {
      this.vEntorno = this.$store.state.sites;
      this.tab = this.vEntorno.events[this.vEntorno.indexEveSel].tabs ? this.vEntorno.events[this.vEntorno.indexEveSel].tabs[0] : 0;
      this.checkGroups();
    },
    changetab(tab) {
      if(this.loadingTicketsCategory || this.loadingData) return false
      this.tickets = null;
      this.tab = tab;
    },
    async checkGroups() {
      if(this.vEntorno.events[this.vEntorno.indexEveSel].categories.length === 0) return false;

      this.currentTicketGroup = this.$store.getters['sites/getCurrentCategory'](this.tab)
      this.manyGroups = this.currentTicketGroup["ticketsGroups"].length === 1
      if(this.manyGroups) {
        await this.displayTickets(this.currentTicketGroup["ticketsGroups"][0]);
      } else {
        this.tickets = null;
      }  
      this.showTickets = this.currentTicketGroup["ticketsGroups"].length === 1;
    },
    toggleAbout() {
      if (this.aboutDroppedDown == false) {
        this.aboutDroppedDown = true;
        this.showEventContent = false;
      } else {
        this.aboutDroppedDown = false;
        setTimeout(() => {
          this.showEventContent = true;
        }, 500);
      }
    },
    async displayTickets (groupZ) {
      this.showTickets = true;
      if (!groupZ.ticketsG) {
        this.$emit("loadTickets", groupZ)
      }     
      this.tickets = groupZ;
    },    
    openCart() {      
      if (this.$route.name != "shoppingCart") {
        this.$router.push({ name: "shoppingCart" });
      }
    },
  },
};
</script>

<style scoped>
.table-tickets-image {
  max-width: 1300px;
  margin: 0px auto;
}
.Mytab {
  font-size: 12px;
  display: flex;
  max-width: 1300px;
  width: 100%;
  border-bottom: 2px solid #252525;
}
.light {  
  border-bottom: 2px solid #c0c0c0;
}
.Mytab .tab {
  padding: 8px 10px;
  border-radius: 5px 5px 0px 0px;
  font-weight: bold;
  letter-spacing: 2px;
}
.Mytab .tab:hover:not(.active){
  background: rgb(73, 69, 69);
  cursor: pointer;
}
.Mytab.light .tab:hover:not(.active) {  
  background: #d2d6db;
}
.Mytab .active {
  background: #252525;
}
.Mytab.light .active {
  background: #c9d2db;
}
.skeleton-container {
  max-width: 1300px;
}
.DivCategory {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 1000px;
}
.DivTable {
  display: flex;
  justify-content: space-evenly;
}
.btnArrow {
  font-size: 50px;
}
.imgShadow {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}
.imgeSite {
  background-size: cover;
  background-position: 15% 15%;
  background-blend-mode: multiply;
  background-color: #626060;
}
.contInfo {
  padding: 40px 40px 0px 40px;
  position: absolute;
  top: 10px;
  width: 100%;
}
.DivAbout {
  padding-top: 20px;
  height: 100px;
  overflow: hidden;
  transition: height 0.5s, transform 0.5s;
}
.open {
  height: calc(100vh - 200px) !important;
  overflow: auto !important;
}
.backBtn {
  display: flex;
  flex-direction: row;
  width: fit-content;
  position: absolute;
  gap: 10px;
  top: 35px;
  left: calc(100% - 250px);
}
b {
  font-size: 1.5vw !important;
  padding-right: 0.5rem !important;
}
.animation {
  position: absolute;
  top: calc(50%);
  right: calc(50%);
}
.menu-option {
  margin-top: 0px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
} 
/* .menu-option:hover {
  border: 1px solid red;
  background-color: pink;
  border-radius: 30px;
} */
.MyBadge{
  position: absolute;
  width: 25px;
  height: 25px;
  padding: 4px 0px !important;
  background: #00849b;
  border: white 1px solid;
  border-radius: 20px;
  z-index: 1;
  text-align: center !important;
  font-weight: 200;
  color: white;
  font-size: 10px;
  top: calc(20% - 15px);
  left: calc(50%);
}

@media (max-width: 1301px) {
  .Mytab {
    margin-left: 30px !important;
    margin-right: 30px !important;
    width: auto;
  }

  .skeleton-container {
    margin-left: 30px !important;
    margin-right: 30px !important;
    width: auto;
  }
  .MyBadge{
    width: 20px;
    height: 20px;
    padding: 3px 0px !important;
    font-weight: bold;
    color: white;
    font-size: 9px;
    top: calc(0%);
    left: calc(50%);
  }
}

@media (max-width: 959px) {
  .table-tickets-image {
    max-width: 500px;
    margin: 0px auto;
  }
}

@media only screen and (max-width: 700px) {
  .btnArrow {
    font-size: 30px;
  }
  .contInfo {
    padding: 20px 20px 0px 20px;
  }
  h1 {
    font-size: 20px;
  }
  h4 {
    font-size: 12px;
  }
  .backBtn {
    top: 13px;
    /* left: calc(100% - 76px); */
    left: calc(100% - 110px);
  }
  .animation {
    top: calc(35%);
    right: calc(50%);
  }
  
  .Mytab {
    display: flex;
    flex-direction: column;
    margin-left: 15px !important;
    margin-right: 15px !important;
    border-bottom: 0px;
    border-left: 2px solid #252525;
  }
  
  .Mytab .tab {
    border-radius: 0px 5px 5px 0px;
  }
  /* .imgeSite {
    height: 260px;
  } */
}
</style>