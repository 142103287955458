<template>
 <v-card flat class="mx-auto my-5 py-5 MyCard1" color="transparent" style="max-width:1300px !important;">
   <template v-slot:header>
     <v-row>
       <v-col>
         <h6 class="mb-0" style="text-align: left">
           {{ $t("actions.nextEvent") }}
         </h6>
       </v-col>
     </v-row>
   </template>
   <div class="MyPadding">
     <strong class="textSub ml-md-12" 
      :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
      {{ total }} {{ $t("actions.Events") }}
    </strong>
   </div>
    <v-container class="d-flex flex-column flex-md-row flex-lg-row align-start justify-center">
      <v-col class="pt-0 show" cols="12" md="5" lg="4" style="min-height: 450px !important;">
        <v-date-picker
          :dark="$store.state.corpInfo.darkTheme"
          color="var(--color-primary)"
          :header-color="$store.state.corpInfo.darkTheme ? 'grey darken-4' : 'var(--color-primary)'"
          elevation="5"
          v-model="date"          
          :locale="$root.getTextlang()"
          full-width
          style="min-height: 466px !important;"
          :allowed-dates="avialableDates"
          :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"          
          class="mt-4"
          :disabled="loadingDateSchedules"
          scrollable
        ></v-date-picker>
      </v-col>
      <v-col cols="12" class="showmobile">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="100%"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              :dark="$store.state.corpInfo.darkTheme"
              persistent-hint
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :dark="$store.state.corpInfo.darkTheme"
            color="var(--color-primary)"
            v-model="date"
            :allowed-dates="avialableDates"
            :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"   
            no-title
            full-width
            @input="menu = false"            
            :locale="$root.getTextlang()"
            :disabled="loadingDateSchedules"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="showmobile">
        <div class="MyPadding FixPagination" v-if="datesForToday === true">
          <template class="FixPagination">
            <div class="text-center FixPagination" style="display: flex; justify-content: space-around;" v-if="EventX" >
              <v-container class="FixPagination">
                <v-row justify="center">
                  <v-col cols="8" class="FixPagination">
                    <v-container class="max-width FixPagination" v-if="total > 3">
                      <v-pagination
                        v-model="page"
                        :length="EventX2.length"
                        color="var(--color-primary)"
                        class="my-4"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </template>
        </div>
      </v-col>
      <v-col class="pt-2 pt-md-7 pt-lg-7 px-6 px-md-4 px-lg-4" cols="12" md="7" lg="8">
        <v-row v-if="datesForToday">
        <div class="mx-0" v-if="!EventX2[page-1]" style="width: 100%;">

          <v-skeleton-loader color="primary" :dark="$store.state.corpInfo.darkTheme" v-for="index in 1" :key="index"
            type="list-item-avatar-three-line, actions">
          </v-skeleton-loader>
        </div>
        <v-list :color="$store.state.corpInfo.darkTheme?'grey darken-4':'white'" v-else style="width: 100%;">
          <v-list-item v-for="(item, index) in EventX2[page-1]" :key="index">

            <dateComponent style="width: 100%;" :item="item" :index="index" :EventX2="EventX2" :page="page"
              @selectFindTickets="OpenMap"/>

          </v-list-item>
        </v-list>
        </v-row>
        <v-row v-else>    
          <RecommendationDateX 
            v-if="vEntorno" 
            :nextDate="vEntorno.events[vEntorno.indexEveSel].avialableDates[0]"
            @changeDate="changeDate" /> 
        </v-row>
      </v-col>     
    </v-container>
   <div class="MyPadding FixPagination" v-if="datesForToday === true">
     <template class="FixPagination">
       <div class="text-center FixPagination" style="display: flex; justify-content: space-around;" v-if="total > 3" >
         <v-container class="FixPagination">
           <v-row justify="center">
             <v-col cols="8" class="FixPagination">
               <v-container class="max-width FixPagination" v-if="total > 3">
                 <v-pagination
                   v-model="page"
                   :length="EventX2.length"
                   color="var(--color-primary)"
                   class="my-4"
                 ></v-pagination>
               </v-container>
             </v-col>
           </v-row>
         </v-container>
       </div>
     </template>
   </div>
 </v-card>
</template>

<script>
import RecommendationDateX from '../Tools/RecommendationDate.vue';
import dateComponent from '../Tools/dateListComponent.vue'

export default {
 name: "DatesList",
 props: ['resetDates'],
 components: {
  RecommendationDateX,
  dateComponent
 },
 data: () => ({
   vEntorno: null,
   value: null,
   total: 0,
   EventX: [],
   EventX2: [],
   page: 1,
   elemXloyaut:3,      //Aqui se delimita cuantos registros por paginacion
   LimitPag:1,
   attrs: {
     class: 'mb-6',
     boilerplate: true,
     elevation: 2,
   },
   date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
   menu: false,
   datesForToday: true,
   loadingDateSchedules: false
 }),
 watch : {
  date: function (val) {
    this.LoadNewDates(val);
  },
  resetDates: function (val) {
    this.date = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10);
    this.LoadEventInfo();
  }
 },
 mounted() {
   this.vEntorno = this.$store.state.sites;
   this.img = this.vEntorno.events[0].img;
   this.name = this.vEntorno.events[0].name;
   this.LoadNewDates(this.date);
 },
 methods: {
   LoadEventInfo() {    
    this.$root.loading = false;
    this.EventX = this.datesForToday === false ? [] : this.vEntorno.events[this.vEntorno.indexEveSel].events;
    this.total = this.datesForToday === false ? 0 : this.EventX.length;
    this.ProcesarPagination();
   },
   ProcesarPagination(){
      this.page = 1;
      this.vEntorno = this.$store.state.sites;
     this.EventX2 = [];
     if(this.vEntorno.events[this.vEntorno.indexEveSel].events){
             
       if(this.vEntorno.events[this.vEntorno.indexEveSel].events.length > 0){
         let num = parseInt(this.vEntorno.events[this.vEntorno.indexEveSel].events.length / this.elemXloyaut)
         if( num == 0){
           this.LimitPag = 1
         }else{
           this.LimitPag = num
         }
         for(var x=0; x < this.vEntorno.events[this.vEntorno.indexEveSel].events.length; x = x + this.elemXloyaut){
           this.EventX2.push(this.vEntorno.events[this.vEntorno.indexEveSel].events.slice(x, x +this.elemXloyaut));
         }
       }else{
         this.LimitPag = 1
         this.EventX2 = this.EventX
       }
     }else{
       this.LimitPag = 0
       this.EventX2 = []
     }
   },
   async OpenMap(item, index) {
     if( parseInt(item.usados) >= parseInt(item.max_seat) ){
       return false
     }
    this.$store.dispatch('sites/selectEventDate', { eventDate: item, index: index })
    await this.$emit('displayTickets', item)
   },
   async LoadNewDates(newDate) {
    let self = this;
    let config = this.vEntorno.events[0].event_config;
    this.datesForToday = true;
    this.EventX2 = []; 
    await this.LoadEventDate(newDate).then(async (res) => {
      this.datesForToday = res;
      if(res) {
        if(config === 4 ) {
          self.$emit("loadCategories", this.vEntorno.events[0])
        }
      }
      this.datesForToday = res
      this.LoadEventInfo()
    });
   },
   async LoadEventDate(date_event) {
      this.loadingDateSchedules = true
      let event_configT = this.vEntorno.events[0].event_config;
      let today = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        
      let response = await this.$root.post("/ServiceClient/GetDateSchedules", {
        id_event: this.vEntorno.events[0].id_event,
        date_event: date_event ? date_event : today,
        event_config: event_configT,
        host: this.$root.hostname
      });      
      this.loadingDateSchedules = false
      if (response.data.success == "FALSE") {
        this.$root.loading = false;
        return false
      } else {
        this.vEntorno.events[0].events = response.data.Data;
        this.$root.loading = false;
        this.$store.dispatch('sites/chargeCurrentEvent', this.vEntorno.events[0])
        return true
      }
    },
   avialableDates (val) {
    if(this.vEntorno && val) return this.vEntorno.events[0].avialableDates.indexOf(val) !== -1 || val == (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
   },
   changeDate(newDate) {
    this.date = newDate;
   }
 },
};
</script>

<style scoped>

.v-picker >>> .v-date-picker-table--date .v-btn {
  height: 40px !important;
  width: 40px !important;
}
.v-picker >>> .v-btn__content{
  font-size: 1.4rem !important;
}
.showmobile {
  display: none !important;
 }
.FixPagination{
 padding: 0 !important; margin: 0 !important;
}
.MyCard1 {
 max-width: 100% !important;
 border: 0;
 zoom: 95%;
}
.MyPadding {
 padding: 0px 20px 0px 20px !important;
}
.textSub {
 text-align: left;
 font-weight: bold;
}
@media only screen and (max-width: 1600px) {
 .MyCard1 {
   max-width: 70%;
 }
}

@media only screen and (max-width: 1200px) {
 .MyCard1 {
   max-width: 80%;
 }
}

@media only screen and (max-width: 800px) {
  .v-picker >>> .v-btn__content{
    font-size: 1.2rem !important;
  }
  .v-picker >>> .v-date-picker-table--date .v-btn {
    height: 31px !important;
    width: 31px !important;
  }
  .show {
    display: none !important;
  }
  .showmobile {
    display: inline !important;
  }
}
</style>
