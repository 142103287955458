<template>
  <div class="event-categories">
    <div v-for="(groupX, index) in item.ticketsGroups" :key="groupX.id_cataloge">
      <v-row class="my-6" v-if="groupX.id_category === tab">
        <v-col lg="3" md="4" cols="12" order-md="1" order="2">
          <div class="ImgTickets mx-auto">
            <div class="back" :class="[{'light':!$store.state.corpInfo.darkTheme}]"></div>
            <v-carousel
              class="front" 
              :class="[{'light':!$store.state.corpInfo.darkTheme}]"
              :interval="3400"
              cycle
              height="191px"
              hide-delimiter-background
              show-arrows-on-hover>
              <v-carousel-item
                @click="show(groupX)" 
                v-for="(slide, i) in groupX.images"
                :key="i"
                :src="$root.UrlGallery + slide" >
              </v-carousel-item>
            </v-carousel>
          </div>
        </v-col>
        <v-col lg="5" md="4" cols="12" order-md="2" order="1" align-self="center">
          <p class="title-info">{{ groupX.title[$root.getTextlang()] }}</p>
          <p class="descrip-info mb-3">{{ groupX.description[$root.getTextlang()] }}</p>
          <div v-for="(value, key) in groupX.info[$root.getTextlang()]" :key="groupX.id_cataloge + '-' +key" class="info-container mb-2">
            <v-icon :color="$store.state.corpInfo.darkTheme?'white':'black'" size="15" style="min-width: 15px;"> {{ value.icon }} </v-icon>
            <span class="descrip-info">{{ value.info }}</span>
          </div>
        </v-col>
        <v-col lg="4" md="4" cols="12" order-md="3" order="3" align-self="center">
          <v-btn class="mx-auto d-block" color="#3A3A3A" @click="displayTickets(groupX)">
            <span class="white--text">{{$t("actions.seeTickets")}}</span>
            <v-icon color="white" class="pl-2">mdi-playlist-check</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <Gallery ref="GalleryDialog" />
  </div>
</template>

<script>
import Gallery from './GalleryDialog.vue';

export default {
  name: "EventCategory",
  props: ["tab", "item"],
  components : {
    Gallery
  },
  data() {
    return {
      vEntorno: null
    };
  },
  created() {
    this.vEntorno = this.$vEntorno;
  },
  methods: {
    displayTickets(groupT) {
      this.$emit("displayTickets", groupT);
    },
    show(item) {
      this.$refs.GalleryDialog.begin(item);
    },
    getIntervalTime: function (index) {
      let interval = 3000
      if (index % 2 == 0) {}
      else interval = 3200
      return interval
    }
  },
};
</script>

<style scoped>
.event-categories {
  max-width: 1300px
}

.ImgTickets {
  width: 286px;
  height: 191px;
  position: relative;
}

.ImgTickets .back {
  width: 100%;
  height: 100%;
  top: calc(0% - 12px);
  background: #1e1d1d;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
}

.ImgTickets .front {
  margin-left: 12px;
  border: 1px solid rgb(43, 43, 43);
  width: 100%;
  height: 100%;
  top: 0px;
  border-radius: 10px;
  z-index: 2;
  position: absolute;
  background-size: cover;
  cursor: pointer;
}
.light {  
  border: 1px solid #fcfcfc !important;
  background: #c5c5c5 !important;
}

.title-info {
  font-size: 20px;
}

.descrip-info {
  max-height: 50px;
  overflow-wrap: break-word;
  overflow: hidden;
  font-size: 12px;
}

.info-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 1300px) {
  .event-categories {
    margin-left: 30px !important;
    margin-right: 30px !important;
    width: auto;
  }
}

@media (max-width: 700px) {
  .event-categories {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}
</style>