<template>
  <div class="event-preview-style"
    :style="{'background-color': $store.state.corpInfo.darkTheme ? 'black':'#E2E6EF'}">
    <NotFoundBody v-if="notFound" class="d-flex justify-center align-center"/>
    <div v-if="!notFound && eventData" style="height: 100%; overflow-y: scroll; overflow-x: hidden;">      
      <FreeEntryEvent v-if="eventData.event_config === 3" :item="eventData" 
        :loadingTicketsCategory="loadingTicketsCategory"
        @loadTickets="loadTickets" :loadingData="loadingData" />
      <LimitEntryEvent v-else :item="eventData" :loadingData="loadingData" 
        :loadingTicketsCategory="loadingTicketsCategory"
        @loadingCategories="loadLimitedEventInfo"/>
    </div>
  </div>
</template>

<script>
import NotFoundBody from '../components/404-NotFound/404-body.vue';
import FreeEntryEvent from '../components/Events/FreeEntryEvent.vue';
import LimitEntryEvent from '../components/Events/LimitedEntryEvent.vue';

export default {
  components: {
    FreeEntryEvent,
    LimitEntryEvent,
    NotFoundBody
  },
  data() {
    return {
      eventData: null,
      loadingData : false,
      notFound: false,
      loadingTicketsCategory: false
    }
  },
  mounted() {
    let captcha = document.getElementsByClassName('grecaptcha-badge')
    if (captcha && captcha.length > 0) {
      captcha[0].remove();
    }
    this.$root.resetShoppingTime()    
    // console.log(this.$store.state.shopping.regularSellTickets)
    // console.log(this.$store.state.shopping.stadisticsValue)

    this.$store.dispatch('shopping/resetStadisticsValue');
    this.$root.loading = true;
    this.$root.token = this.$store.getters['auth/getUserS']?.token_cliente
    this.loadEventDetails()
  },
  methods: {
    checkUrl(id_event) {
      if(this.$route.query.e != this.eventData.name[this.$root.getTextlang()] && this.$route.query.e != id_event) {
        this.$router.replace({ 
          name: 'eventPreview', 
          query: { e: this.eventData.name[this.$root.getTextlang()], v: this.$route.query.v } 
        });
      } 
    },
    loadEventDetails() {
      this.$root
      .post("/ServiceClient/GetEvent", {
        id_event: this.$route.query.v,
        host: this.$root.hostname
      })
      .then((response) => {
        let info = response.data
        if(info.success == "FALSE") {
          this.notFound = true;
        } else {
          this.eventData = info.Data[0]
          this.checkUrl(this.eventData.id_event);          
          this.$store.dispatch('sites/chargeCurrentEvent', info.Data[0])
          if(this.eventData.event_config === 3) this.loadCategories(this.eventData); 
        }
      })
      .catch((error) => {
        this.notFound = true;
      })
      .finally(() => {
        this.$root.loading = false;
      });
    },
    async loadLimitedEventInfo(info){
      info.tabs = [];
      if(info.categories.length > 0) await this.loadTickets(info.categories[0].ticketsGroups[0]);
    },
    async loadCategories(info) {
      this.loadingData = true;
      let tabs = [];
      info.categories.forEach(item => {
        tabs.push(item.id_category);
        item["ticketsGroups"].forEach(groupT => {
          groupT.info = groupT.info ? JSON.parse(groupT.info) : {};
        });
      });  
      info.tabs = tabs;
      if(info.categories.length > 0) {
        if(info.categories[0].ticketsGroups.length === 1)  await this.loadTickets(info.categories[0].ticketsGroups[0]);
      }
      this.$store.dispatch('sites/chargeCurrentEvent', info)
      this.loadingData = false;
    },
    async loadTickets (groupT) {
      this.loadingTicketsCategory = true
      this.loadingData = true;

      let data = this.$store.state.sites.events[0];
      if (!data) return false
        
      let response = await this.$root.post("/ServiceClient/getTicketsFromGroup", {
        host: this.$root.hostname,
        id_event: data.id_event,
        id_cataloge: groupT.id_cataloge
      })
      let info = response.data;
      if (info.success === 'TRUE') {
        groupT.ticketsG = info.Data;
      } else {
        groupT.ticketsG = [];
        // self.$root.swalAlert("error", "", info.Msg);
      }
      this.loadingData = false;      
      this.loadingTicketsCategory = false
      return true
    }
  }
}
</script>

<style scoped>
.event-preview-style {
  height: 100%; 
  overflow: hidden;
}
</style>